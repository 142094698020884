import { render, staticRenderFns } from "./newsDetails_new_hj.vue?vue&type=template&id=e4084b22&scoped=true"
import script from "./newsDetails_new_hj.vue?vue&type=script&lang=js"
export * from "./newsDetails_new_hj.vue?vue&type=script&lang=js"
import style0 from "./newsDetails_new_hj.vue?vue&type=style&index=0&id=e4084b22&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/.store/vue-loader@15.11.1/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e4084b22",
  null
  
)

export default component.exports