<template>
  <div class="page_container">
    <div class="content_breadcrumb">
      <div class="content_nav">
        当前位置&nbsp;： 新闻资讯 &nbsp;&nbsp; >&nbsp;&nbsp;
        <span class="content_detail">详细内容</span>
      </div>
    </div>
    <div class="news_content container">
            <!-- <div class="content_title">
                “黑洞引擎”荣获“上海市第二届BIM技术应用创新大赛”特别创意优秀奖
            </div>
            <div class="content_time">
                发布时间：2020-8-10 17:20
            </div>
            <div class="content_report">
                <div class="content_report_text">
                    8 ⽉10 ⽇， 在《上海绿⾊建筑发展报告》和《上海市建筑信息模型技术应⽤与发展报告》发布会上， 秉匠科技荣获由上海绿⾊建筑协会及上海建筑信息模型技术应⽤推⼴中⼼举办的“ 上海市第⼆届BIM 技术应⽤创新⼤赛” 特别创意奖“ 优秀奖”。
                </div>
                <div class="content_report_picture">
                    <img src="../../assets/image/news/new_hj.jpg" alt="">
                </div>
                <div class="content_report_text">
                    <p>
                        此次参与评选的有来⾃建筑施⼯、 勘察设计、 市政基建、软件技术、咨询运维和医疗领域的国内知名企业。
                    </p>
                    <p>
                        经专家评审后， 最终评选出共计56 项获奖项⽬， 其中技术⽅案奖 50 项（ 房建类 31 项、 市政类 19 项） ， 特别创意奖 6 项。（ 优秀奖3 项、佳作奖3 项）
                    </p>
                </div>
                <div class="content_report_picture">
                    <img src="../../assets/image/news/new_hj2.jpg" alt="">
                </div>
                <div class="content_report_text">
                    <p>
                        本次获奖的“ ⿊洞引擎” （ Black Hole-Engine ） 是秉匠科技完全⾃主研发、具备国际领先⽔平的BIM+ GIS 轻量化渲染引擎。
                    </p>
                    <p>
                        主要为⼤型⼟⽊⼯程参建各⽅提供Web 端基于多源异构B IM 模型的数据可视化承载平台， 解决设计、 建造、运维过程中的可视化沟通共享问题。同时也可以为城市 CIM应⽤提供底层核⼼数据⽀持。
                    </p>
                </div>
                <div class="content_report_picture">
                    <img src="../../assets/image/news/new_hj3.jpg" alt="">
                    <img src="../../assets/image/news/new_hj4.jpg" alt="">
                </div>
            </div> -->
      <div class="left">
        <div class="tit">
          <h1>“黑洞引擎”荣获“上海市第二届BIM技术应用创新大赛”特别创意优秀奖</h1>
          <div class="time">发布时间：2020-8-10 17:20</div>
          <div class="info">
            <p>  8 ⽉10 ⽇， 在《上海绿⾊建筑发展报告》和《上海市建筑信息模型技术应⽤与发展报告》发布会上， 秉匠科技荣获由上海绿⾊建筑协会及上海建筑信息模型技术应⽤推⼴中⼼举办的“ 上海市第⼆届BIM 技术应⽤创新⼤赛” 特别创意奖“ 优秀奖”。</p>
            <p class="p_pic">
              <span style="font-size: 14px;"><img src="../../assets/image/news/new_hj.jpg"></span>
            </p>
            <p>此次参与评选的有来⾃建筑施⼯、 勘察设计、 市政基建、软件技术、咨询运维和医疗领域的国内知名企业。</p>
            <p> 经专家评审后， 最终评选出共计56 项获奖项⽬， 其中技术⽅案奖 50 项（ 房建类 31 项、 市政类 19 项） ， 特别创意奖 6 项。（ 优秀奖3 项、佳作奖3 项）</p>
            <p  class="p_pic">
              <span style="font-size: 14px;"><img src="../../assets/image/news/new_hj2.jpg"></span>
            </p>
            <p>本次获奖的“ ⿊洞引擎” （ Black Hole-Engine ） 是秉匠科技完全⾃主研发、具备国际领先⽔平的BIM+ GIS 轻量化渲染引擎。</p>
            <p>主要为⼤型⼟⽊⼯程参建各⽅提供Web 端基于多源异构B IM 模型的数据可视化承载平台， 解决设计、 建造、运维过程中的可视化沟通共享问题。同时也可以为城市 CIM应⽤提供底层核⼼数据⽀持。</p>
            <p  class="p_pic">
              <span style="font-size: 14px;"><img src="../../assets/image/news/new_hj3.jpg"></span>
            </p>
            <p  class="p_pic">
              <span style="font-size: 14px;"><img src="../../assets/image/news/new_hj4.jpg"></span>
            </p>
          </div>
        </div>
      </div>
      <div class="right">
        <div class="tit"><h3><font></font>热点内容</h3></div>
        <ul>
          <li v-for="item in hotList" :key="item.id">
            <p><router-link :to="item.link">{{item.name}}</router-link></p>
            <span>{{item.time}}</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      hotList: [
        {id:1,name: '“黑洞”图形引擎助力中电建华东院打造智慧城市CIM平台',time: '2020-11-09',link:'/newsDetails_new_CIM'},
        {id:2,name: '秉匠科技又双叒叕获奖了！',time: '2020-10-28',link:'/newsDetails_new_BIM'},
        {id:3,name: '江苏东交智控科技集团股份有限公司与秉匠科技签订“黑洞”图形引擎采购合同',time: '2020-11-02',link:'/newsDetails_new_dongjiao'},
        {id:4,name: '“黑洞引擎”荣获“上海市第二届BIM技术应用创新大赛”特别创意优秀奖',time: '2020-8-10',link:'/newsDetails_new_hj'}
      ]
    }
  },
  mounted () {
      document.querySelector('.nav').style.backgroundColor = '#000'
  },
  destroyed () {
      document.querySelector('.nav').style.backgroundColor = 'transparent'
  }
}
</script>

<style lang="less" scoped>
.page_container{
  margin-top: 50px;
  .news_content{
    overflow: hidden;
    .left{
      width: 60%;
      height: 100%;
      .tit{
        h1{
          color: #222;
          font-size: 22px;
          font-weight: normal;
          line-height: 30px;
        }
        .time{
          margin: 10px;
          height: 27px;
          border-bottom: 1px #ddd solid;
          font-size: 14px;
          color: #999;
          padding-right: 30px;
        }
      }
      .info{
        padding-top: 32px;
        text-align: center;
        p{
          font-size: 16px;
          color: #222;
          line-height: 28px;
          text-align: left;
          text-indent:2em;
        }
        .p_pic{
          text-indent: 0;
        }
        img{
          margin: 16px 0;
          max-height: 400px;
        }
      }
    }
    .right{
      width: 35%;
      height: 100%;
      .tit{
        border-bottom: 1px #444 solid;
        padding-bottom: 14px;
        margin-top: 24px;
        font{
          width: 4px;
          height: 18px;
          background: #222;
          display: inline-block;
          margin-right: 16px;
          position: relative;
          top: 4px;
        }
      }
      ul{
        padding: 12px;
        li{
          line-height: 24px;
          font-size: 16px;
          color: #555;
          border-bottom: 1px #ddd solid;
          padding: 12px 0;
          p{
            a{
              color: #555;
            }
          }
          span{
            display: block;
            font-size: 14px;
            color: #777;
            padding-top: 4px;
          }
        }
        .router-link-active{
          border-bottom: none;
        }
      }
    }
  }
}
</style>
